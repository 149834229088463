import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import scrollTo from "gatsby-plugin-smoothscroll"
import StarRating from "react-svg-star-rating"
import { Link } from "gatsby"
import styled from "styled-components"
import ReviewContainer from "../components/styles/ReviewContainer"
import MainButton from "../components/styles/MainButton"
import RelatedBikes from "../components/RelatedBikes"

class BikeReview extends React.Component {
  render() {
    const affiliateLink = "https://www.roguefitness.com/concept2-bike-erg?a_aid=5e7fb64738ac3"
    const linkText = "$990 from Rogue Fitness"

    return (
      <Layout>
        <SEO
          title="Concept 2 BikeErg In-Depth Review 2019"
          description="The Concept2 BikeErg is the latest addition to the Concept2 family, featuring the same flywheel and Performance Monitor as the Concept2 Indoor Rowers and SkiErgs, bringing cycling the strengths and features previously brought to rowing and cross-country"
        />
        <ReviewContainer>

          <div className="r-post">
            <div>
              <aside className="r-article-toc">
                <div className="product-name">
                  <p className="article-type">In-Depth Review</p>
                  <h2>Concept2 BikeErg</h2>
                  <p>Updated January 2, 2020</p>
                </div>
                <div>
                  <div className="r-article-toc--btn">Table of Contents</div>
                </div>
                <div className="r-article-toc--contents">
                  <ul>
                    <li>
                      <span onClick={() => scrollTo("#n0")}>Review</span>
                    </li>
                    <li>
                      <span onClick={() => scrollTo("#n1")}>
                        Pros and Cons of Concept2 BikeErg
                      </span>
                    </li>
                    <li>
                      <span onClick={() => scrollTo("#n2")}>
                        What sets the Concept2 BikeErg Apart?
                      </span>
                    </li>
                    <li>
                      <span onClick={() => scrollTo("#n3")}>
                        BikeErg Comparison
                      </span>
                    </li>
                    <li>
                      <span onClick={() => scrollTo("#n4")}>
                        BikeErg Rating
                      </span>
                    </li>
                    <li>
                      <span onClick={() => scrollTo("#n5")}>Where to buy</span>
                    </li>
                  </ul>
                </div>
                <div className="r-article-toc--cta">
                  <MainButton
                    href={affiliateLink}
                    target="_blank"
                  >
                    {linkText}
                  </MainButton>
                </div>
              </aside>
            </div>
            <div className="r-post-content">
              <div className="r-post-container">

                <div className="r-product-box">
                  <div className="product-box-brand">Concept2</div>
                  <div className="product-left">
                    <img src="/images/bike-erg/concept2-bike-erg.png" />
                  </div>
                  <div className="product-right">
                    <a
                      href={affiliateLink}
                      target="_blank"
                    >
                      Concept2 BikeErg
                    </a>
                    <p>
                      The BikeErg is the latest innovation from the makers of
                      the Concept2 Indoor Rower and SkiErg. Utilizing the same
                      advanced engineering principles and PM5 performance
                      monitor as those time-tested machines.
                    </p>
                    <div>
                      <MainButton
                        href={affiliateLink}
                        target="_blank"
                      >
                        {linkText}
                      </MainButton>
                    </div>
                  </div>
                </div>
                <p className="r-post-intro">
                  The <span className="brand-name">Concept2 BikeErg</span> is a
                  worthy upgrade over an existing stationary bike or a logical
                  addition for a gym already equipped with the popular Concept2
                  Rower and SkiErg. These machines are all built to last and
                  utilize the same flywheel technology and advanced monitor
                  system to give any athlete an efficient, consistent training
                  experience.
                </p>

                <span id="n0"></span>
                <h2>Review</h2>
                <p>
                  The Concept2 BikeErg is portable, easy to assemble, and
                  designed with an adjustable saddle and handlebars. Users can
                  even decide to attach their own bicycle seat, handlebars or
                  pedals to create the ultimate custom indoor bike.
                </p>
                <p>
                  Rather than a chain, the BikeErg features high-strength,
                  self-tensioning polygroove belts, greatly reducing the sound
                  output and making set-up practical in any room of the house.
                </p>
                <p>
                  The BikeErg utilizes the same advanced engineering principles
                  and PM5 performance monitor as those time-tested machines, the
                  BikeErg is also lighter in weight than most stationary bikes
                  and closer in feel to actual outdoor riding. The design is
                  built around precision flywheel air resistance, creating a
                  workout customized to any athlete who uses it. As you pedal
                  harder, the intensity and challenge of the workout increases
                  accordingly. At the same time, the inclusion of a clutch lets
                  you freewheel like a standard bicycle, while a wide damper
                  range re-creates the effect of changing gears.
                </p>
                <p>
                  The Concept2 stationary bike also features a self-powered
                  backlit display with built-in programs. Additionally, it
                  features a USB port, Bluetooth and ANT+ wireless connectivity
                  to allow connect the bike to your smart devices and
                  download/upload, track and save your workouts.
                </p>
                <p>
                  The Concept2 BikeErg brings enough features to easily justify
                  its price tag of $1000. The Concept2 BikeErg easily earns a
                  4.5 out of 5 star rating and is one of our top rated Air Bikes
                  for 2020.
                </p>
                <span id="n1"></span>
                <h2>Pros and Cons of Concept2 BikeErg</h2>
                <h3>Positives:</h3>
                <ul className="r-content-list-positives">
                  <li>
                    The Concept2 spin bike is designed with commercial grade
                    quality parts and aluminum frame
                  </li>
                  <li>
                    The BikeErg requires very little assembly and they include
                    all necessary tools plus clear instructions with lots of
                    pictures.
                  </li>
                  <li>
                    The four-way handlebars adjustments and the typical road
                    bike design make it easy to create the perfect fit for most
                    riders.
                  </li>
                  <li>
                    The PM5 monitor on the BikeErg has both USB, Bluetooth, and
                    ANT+ wireless connectivity, allowing it to connect to many
                    heart rate belts, fitness devices, and apps.
                  </li>
                  <li>
                    The BikeErg’s aluminum frame makes it both light and
                    durable, while caster wheels make it easy to move around. At
                    just 58 lbs, it’s lighter than most other stationary bikes.
                  </li>
                  <li>
                    Dynamic resistance for a challenging, responsive, and
                    continuous workout.
                  </li>
                  <li>
                    Pro performance handlebar with drop bar optimized for race
                    training.
                  </li>
                  <li>RPM and Power measurement.</li>
                  <li>
                    Wide range of fitness app compatibility for workout
                    tracking.
                  </li>
                </ul>
                <h3>Negatives:</h3>
                <ul className="r-content-list-negatives">
                  <li>
                    Horizontal adjustment on the seat is time-consuming as you
                    would need to use a wrench to loosen the seat clamp.
                  </li>
                  <li>
                    Add all the key files your team needs for the project or
                    topic
                  </li>
                  <li>
                    The Concept2 indoor cycle features a fan flywheel which
                    makes a bit more noise than friction resistance and magnetic
                    resistance bikes.
                  </li>
                  <li>
                    No tablet holder on the bike and the phone holder is sold
                    separately.
                  </li>
                  <li>
                    Bottle holder to stay hydrated during the workout is not
                    included, sold separately.
                  </li>
                </ul>
                <span id="n2"></span>
                <h2>What sets the Concept2 BikeErg Apart?</h2>
                <h3>BikeErg Features</h3>
                <h4>1. PM5 PERFORMANCE MONITOR</h4>
                <p>
                  Included standard with the unit, the PM5 Performance Monitor
                  offers a wide range of instant data feedback, including pace,
                  watts, cadence and calories. These statistics are then saved
                  and stored automatically in the monitor’s memory or an
                  optional USB stick. The PM5 has both Bluetooth and ANT+
                  wireless options, so you can connect to most wireless heart
                  rate belts, as well as Concept2’s ErgData app and other third
                  party apps. The PM5 takes two D-cell batteries and the power
                  is generated by the flywheel when you ride, saving on battery
                  life.
                </p>
                <img
                  src="/images/bike-erg/features/concept2-bike-erg-pm5.png"
                  alt="PM5 Performance Monitor"
                />

                <h4>2. FLYWHEEL & DAMPER</h4>
                <p>
                  Like the Concept2 Rowers and SkiErg, the BikeErg uses air
                  resistance generated by a fanned flywheel. This makes the
                  machine directly responsive to your own output as you pedal.
                  The inclusion of a special damper also re-creates the effect
                  of shifting gears on a standard bicycle, as the user can alter
                  the amount of air going into the flywheel. By changing
                  settings, you can simulate the experience of a straightaway
                  ride or a challenging uphill climb. The BikeErg is uniquely
                  customizable to the skill sets and preferences of any athlete,
                  regardless of size or experience.
                </p>
                <img
                  src="/images/bike-erg/features/concept2-bike-erg-flywheel.png"
                  alt="Flywheel and Damper"
                />

                <h4>3. QUALITY CONSTRUCTION + EASY MOBILITY</h4>
                <p>
                  Few fan bikes can match the BikeErg for precision engineering,
                  durability, and convenient portability. Set-up takes just a
                  matter of minutes, and the unit’s lightweight welded aluminum
                  frame + a set of caster wheels make it uniquely mobile for a
                  so-called “stationary” bike. The use of self-tensioning
                  polygroove belts also greatly reduces the sound output of the
                  bike, making it a viable option for any room in the house.
                </p>
                <img
                  src="/images/bike-erg/features/concept2-bike-erg-contruction.png"
                  alt="BikeErg Construction"
                />

                <h4>4. ADJUSTABLE & CUSTOMIZABLE</h4>
                <p>
                  The BikeErg is designed with an adjustable saddle and
                  handlebars, which can be moved both up-and-down and
                  forward-and-back into a custom, secure setting. Users can even
                  decide to attach their own bicycle seat, handlebars or pedals
                  to create the ultimate custom indoor bike.
                </p>
                <img
                  src="/images/bike-erg/features/concept2-bike-erg-adjust.png"
                  alt="BikeErg Adjustable"
                />

                <span id="n3"></span>
                <h2>Concept2 BikeErg Comparison</h2>
                <h3>BikeErg vs Airdyne/Assault Bike/Air Bike</h3>
                <p>
                  Firstly, the BikeErg is not like your typical Air Bike. Right
                  away you'll notice the BikeErg does not feature the usual
                  handlebars like its competitors. However, the BikeErg does
                  come equipped with a damper setting much like its Rower and
                  SkiErg family lineup. This damper feature sets the BikeErg
                  apart from all of its competitors. The setting allows you to
                  easily adjust how much pain your willing to take, making it
                  feel more like a traditional air bike all the way to a simple
                  spin bike. The adjustability goes a long way when you might
                  feel like a different type of workout in the future.
                </p>
                <p>
                  The Concept2 BikeErg also comes with the best bike computer in
                  its price class. It’s the PM5 also known as Performance
                  Monitor which is the latest and greatest computer monitor by
                  Concept2. This monitor provides all the feedback, stats, and
                  metrics you need to monitor and improve your performance. You
                  can track your pace, watts, stroke rate/RPM, calories and even
                  heart rate by connecting a wireless chest strap.
                </p>
                <img src="/images/airbike-prices.png" className="price-graph" alt="Air bike prices graph"/>
                <p>
                  What also sets BikeErg apart from traditional air bikes is the
                  155mm Q-Factor, which makes the BikeErg feel like your're
                  riding a real bike. Something you wont feel when using the
                  Airdyne, Assault Bike, and Echo Bike.
                </p>
                <p>
                  If however you're training for the CrossFit Games, then having
                  an Assault Bike or Airdyne is probably a better bet. If you're
                  just looking for a bike for conditioning, intervals, HIIT,
                  sprints, versatility, etc., then the BikeErg is the much
                  superior option to get.
                </p>

                <span id="n4"></span>
                <h2>BikeErg Rating</h2>
                <h3>
                  Concept 2 BikeErg - <span className="score-rating">4.5</span>
                </h3>
                <StarRating
                  isHalfRating={true}
                  isReadOnly
                  initialRating={4.5}
                />
                <div className="rating__graphs" width="100%">
                  <div>
                    <p>Construction - 5.0</p>
                    <progress value="5" max="5"></progress>
                  </div>
                  <div>
                    <p>Durability 5.0</p>
                    <progress value="5" max="5"></progress>
                  </div>
                  <div>
                    <p>Performace - 4.5</p>
                    <progress value="4.5" max="5"></progress>
                  </div>
                  <div>
                    <p>Aesthetics - 3.8</p>
                    <progress value="3.8" max="5"></progress>
                  </div>
                  <div>
                    <p>Customization - 4.5</p>
                    <progress value="4.5" max="5"></progress>
                  </div>
                  <div>
                    <p>Adjustability - 4.0</p>
                    <progress value="4" max="5"></progress>
                  </div>
                  <div>
                    <p>Value - 4.8</p>
                    <progress value="4.8" max="5"></progress>
                  </div>
                </div>
                <p>
                  The Concept2 BikeErg is a worthy upgrade over an existing
                  stationary bike or a logical addition for a gym already
                  equipped with the popular Concept2 Rower and SkiErg. These
                  machines are all built to last and utilize the same flywheel
                  technology and advanced monitor system to give any athlete an
                  efficient, consistent training experience.
                </p>
                <span id="n5"></span>
                <h2>Where to buy</h2>
                <div className="r-product-box">
                  <div className="product-box-brand">Concept2</div>
                  <div className="product-left">
                    <img
                      src="/images/bike-erg/concept2-bike-erg.png"
                      alt="Concept2 Bike-Erg"
                    />
                  </div>
                  <div className="product-right">
                    <a
                      href={affiliateLink}
                      target="_blank"
                    >
                      Concept2 BikeErg
                    </a>
                    <p>
                      The BikeErg is the latest innovation from the makers of
                      the Concept2 Indoor Rower and SkiErg. Utilizing the same
                      advanced engineering principles and PM5 performance
                      monitor as those time-tested machines, the BikeErg is also
                      lighter in weight than most stationary bikes and closer in
                      feel to actual outdoor riding.
                    </p>
                    <div>
                      <MainButton
                        href={affiliateLink}
                        target="_blank"
                      >
                        {linkText}
                      </MainButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ReviewContainer>
        <RelatedBikes 
          bike1="Assault Airbike"
          bike1img="/images/assault-airbike.jpg"
          bikeLink1="/assault-airbike-review"

          bike2="Rogue Echo Bike"
          bike2img="/images/rogue-echo-bike.jpg"
          bikeLink2="/rogue-echo-bike-review"

          bike3="Schwinn AirDyne Pro"
          bike3img="/images/schwinn-airdyne-ad7.png"
          bikeLink3="/schwinn-airdyne-pro-review"
        />
      </Layout>
    )
  }
}

export default BikeReview
